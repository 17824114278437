function Trollface() {

  const style = `
      <style>
        .trollface-container {
          height: 100vh;
          width: 100vw;
          display: flex;
          background-color: white;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .trollface {
          opactiy:0;
          animation: troll 3s;
          animation-iteration-count: infinite;
          position:relative;
        }
      </style>
      `
  return (
    <div className="trollface-container">
      <h1>problem?</h1>
      <img src="https://i.imgur.com/maStk86.png" className="trollface" width="200px"
        alt="trollface" />
      <div dangerouslySetInnerHTML={{ __html: style }} />
    </div>
  );
}
export default Trollface;
