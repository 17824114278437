import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Projects from './pages/Projects';
import CurriculumVitae from './pages/CurriculumVitae';
import Home from './pages/Home';
import TrollFace from './pages/Trollface';
import Pubkey from './pages/Pubkey';

const noFooterPaths = [
  "/id_rsa",
  "/id_rsa.pub",
];


function App() {
  return (
    <Router>
      <div className="App bg-light">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/cv" element={<CurriculumVitae />} />
          <Route path="/id_rsa.pub" element={<Pubkey />} />
          <Route path="/id_rsa" element={<TrollFace />} />
        </Routes>
        {/* only include footer on certain pages */}
        {noFooterPaths.includes(window.location.pathname) ? "" : <Footer />}
      </div>
    </Router>
  );
}

function Footer() {

  const route = new URL(window.location.href).pathname

  console.log(route)

  return (
    <div className="Footer">
      <nav className="flex flex-1 items-center">
        <div className="flex items-center text-white ml-6">
          <img src="./favicon.ico" alt="logo" className="max-w-5"></img>
          <a href="/" className="font-semibold text-xl tracking-tight p-5">lmnd</a>
          <div className="flex">
            {/* <p className="font-semibold text-xl tracking-tight p-5">-</p> */}
            <a href="/projects" className="font-semibold text-xl tracking-tight p-5">projects</a>
            <a href="/cv" className="font-semibold text-xl tracking-tight p-5">cv</a>
          </div>
        </div>
      </nav>
    </div>
  );
}


export default App;
