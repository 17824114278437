import React, { useEffect } from 'react';

function Pubkey() {

  const pubkey = `ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABgQDW6MAsEEYwS8TqjDg3ru+wXdutMPiDmrdrtUGCpFD7dITpn9YsmaFCSWzYz8JKYdwCwrIL7RHQwKPxgpMLC4pPwXoU2Kw5IFaKlcb29CuzNtPAO0RECD/tBWTdByB0+UB8dPE0VHIdyPWI/8WdjPNXJHc6eDT1p7q7S8YCiZQ3mqSezswgXhyO5j8aUTchsr7V1d2Psc+1Z25/fKLWdXRUpIbUhXI4nQ8wCrO20/OTgXZtMSVbR6OdWjpuSnybkfOWGKOezzj5raBGNQt4+EddwOUZBrV6AkPdkZm5qhgI2yMvuUYwT6DQRpaBc40B9ZkUsRcdMHotTBcK22Jli60AU6sH7spCls6UG61OR76jFO36Slj4U2iHgExn4wV2PiluOqQVHyn2S6dDxv8ZAz9lU6w2du05av5+CUZeqdfaDIs8p0alFWV/yLF15OekleaxMrjlpYNX4D3LATqa4bEM3ldCN3W7GQgWwjS9ksBVaL/bkX6WDMbvbExX2Iqi/zc= stijn@Stijns-MacBook-Pro.local`

  const style = `
      <style>
        .pubkey-container {
          width: 100%;
          height: 100%;
          background-color: white;
          padding: 5%;
          display: flex;
          align-items: start;
          flex-direction: column;
          gap: 14px;
          font-size: 10px;
          word-break: break-all;
        }
      </style>
      `

  const onClickCopy = () => {
    navigator.clipboard.writeText(pubkey);

    setCopied('Copied');
  }

  const setCopied = (text) => {
    const btn = document.querySelector('.pubkey-container button');
    const originalText = btn.innerText;
    btn.innerText = text;
    btn.style.transition = 'opacity 0.25s';
    btn.style.opacity = '0.5';
    btn.disabled = true;
    setTimeout(() => {
      btn.innerText = originalText;
      btn.style.opacity = '1';
      btn.disabled = false;
    }, 1500);
  }

  const onClickSelectAll = () => {
    const range = document.createRange();
    const selection = window.getSelection();
    const pubkeyElement = document.querySelector('.pubkey-container p');

    range.selectNodeContents(pubkeyElement);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  // Select the contents of the <p> element by default
  useEffect(() => {
    onClickSelectAll();
  }, []);

  return (
    <div className="pubkey-container">
      <p onClick={onClickSelectAll}>
        {pubkey}
      </p>
      <button onClick={onClickCopy}>
        Copy
      </button>
      <div hidden dangerouslySetInnerHTML={{ __html: style }} />
    </div>
  );
}

export default Pubkey;
